export default [
  {
    image: require("@/assets/images/product-01.jpg"),
    desc:
      "极致打造自然年主题空间，将自然博物和主题乐园结合还原生态场景，构建沉浸感知体验"
  },
  {
    image: require("@/assets/images/product-02.jpg"),
    desc:
      "自然课程是认识自然、探索自然、热爱自然的自然科学系列课程。课程设置引导学生锻炼品格、品行、习惯、才艺表达、注意力、自主学习、自我驱动，感恩，善于社会交往，敢于在人前表达等能力于一体发展，并行不悖"
  },
  {
    image: require("@/assets/images/product-03.jpg"),
    desc:
      "自主研发家庭共育自然生态教育产品，集合抚育、生景瓶打造等。将地理、气候、生态结合。同时实现在线教学课+在家动手于一体的亲子产品。另外严选6大一线品牌神态产品。让自然更好的陪伴成长"
  }
];
