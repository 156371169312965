export default [
  {
    avatar: require("@/assets/images/avatar/wangyaming.png"),
    realName: "王雅明",
    label: "教育专家",
    desc:
      "清华大学继续教育学院幼教国培教授\n中科院早期教育指导师\n中国幼儿教育研究会研究员\n中国蒙台梭利教育研究会研究员\n北京市早期教育学科带头人"
  },
  {
    avatar: require("@/assets/images/avatar/lixiaojing.png"),
    realName: "李晓京",
    label: "自然专家",
    desc: "北京林业大学博士后\n擅长鸟类及野生动物保护相关课题"
  },
  {
    avatar: require("@/assets/images/avatar/wudi.png"),
    realName: "吴迪",
    label: "场景设计",
    desc:
      "自然教育全职讲师：资深自然爱好者，超过5年博物讲师，服务过数千场博物活动。曾独立运营及开设蝴蝶农场。"
  },
  {
    avatar: require("@/assets/images/avatar/wangshuang.png"),
    realName: "王爽",
    label: "活动设计",
    desc:
      "自然教育全职讲师：资深自然爱好者，博物讲师，有多年野生动物救助及饲养经验自然图书馆负责人之一，长期从事自然教育科普工作，带队经验丰富"
  },
  {
    avatar: require("@/assets/images/avatar/yangshuang.png"),
    realName: "杨双",
    label: "课程设计",
    desc:
      "从事自然教育工作多年，以扎实的专业知识和丰富的经验为基础，加入风趣灵活的讲解，“小鹅”老师受到了小朋友们的喜爱和家长的们一致好评。\n主题课题：生理生态维度上的动物个性研究"
  },
  {
    avatar: require("@/assets/images/avatar/bixiaoquan.png"),
    realName: "毕晓泉",
    label: "课程设计",
    desc:
      "幼儿自然教育课程研发及培训师；北京市林业碳汇工作办公室自然讲解员；美国双向养育金章导师；国家初级青少年心理辅导员；中国青少年研究中心高级家庭教育指导师；中国科学院心理研究所儿童发展与教育心理学专业在读。"
  },
  {
    avatar: require("@/assets/images/avatar/lindong.png"),
    realName: "林东",
    label: "设计总控/NUT工作室",
    desc:
      "中国第一昆虫设计师;/大型主题乐园设计师;参与制作迪士尼、罗蒙乐园等多家大型萌宠乐园项目。"
  },
  {
    avatar: require("@/assets/images/avatar/dahewenhua.png"),
    realName: "树行途视频团队",
    label: "树行途视频制作部",
    desc:
      "树行途视频制作部/视频制作专业团队，负责树行途课程录制制作/曾为多家公立学校、素质教育机构进行课程录制/对脚本设计、宣传片制作、短视频制作以及活动拍摄后期有着丰富经验"
  },
  {
    avatar: require("@/assets/images/avatar/zhihui.png"),
    realName: "智慧校园平台团队",
    label: "智慧校园平台",
    desc:
      "智慧校园平台/视频制作专业团队，负责树行途课程录制制作/曾为多家公立学校、素质教育机构进行课程录制/对脚本设计、宣传片制作、短视频制作以及活动拍摄后期有着丰富经验"
  },
  {
    avatar: require("@/assets/images/avatar/ecomaping.png"),
    realName: "树行途市场团队",
    label: "树行途市场团队",
    desc:
      "树行途市场部营销部/拥有数位多年从事市场活动品牌打造的专业团队。有着丰富的市场定位、推广进行策划经验/能够有效进行市场控制产品的品牌进行塑造"
  }
];
