







import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ProductCard extends Vue {
  @Prop()
  cover!: string;

  @Prop()
  desc!: string;
}
