



















































































import { Component, Vue } from "vue-property-decorator";
import ProductCard from "@/views/components/ProductCard.vue";
import UserCeo from "@/views/components/UserCeo.vue";
import MemberCard from "@/views/components/MemberCard.vue";

import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import products from "@/mocks/products";
import members from "@/mocks/members";
import { about, honor } from "@/mocks/dict";

@Component({
  components: { MemberCard, UserCeo, ProductCard }
})
export default class Home extends Vue {
  private products = products;
  private groupMembers = members;
  private about = about;

  private time = require("@/assets/images/time.png");
  private partners = require("@/assets/images/partners.jpg");
  private honor = honor;
}
