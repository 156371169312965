





















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MemberCard extends Vue {
  @Prop({
    default: false
  })
  isSmall!: boolean;

  @Prop()
  avatar!: string;

  @Prop()
  realName!: string;

  @Prop()
  label!: string;

  @Prop()
  desc!: string;
}
