











import { Component, Vue } from "vue-property-decorator";

@Component
export default class UserCeo extends Vue {
  private ceo = {
    avatar: require("@/assets/images/avatar/wangxu.png"),
    realName: "王旭",
    label: "CEO\\创始人",
    history: [
      "北京大学光华管理学院MBA",
      "纽约大学Stern商学院高级访问学者",
      "曾任大型上市公司神州数码云计算市场负责人",
      "独立打造云计算上市公司品牌，并带领团队负责整体市场，运营及渠道拓展等工作",
      "北京野生动物保护协会标本委员会会员",
      "康奈尔环境科学合作者",
      "在品牌公关、市场战略产品运营及新媒体方面有丰富经验"
    ]
  };
}
